import React, { FC, useContext, useRef } from "react";
import { graphql, Link } from "gatsby";
import { StoryPageQuery, BlockFragment } from "../../graphql-types";
import { WavySection } from "../components/wavy-section";
import {
  colors,
  ySpaceRem,
  fontStacks,
  targetBaseFontSizePx,
  xSpaceRem,
} from "../global-style";
import { LayoutContext } from "../components/layout";
import { Center } from "../components/center";
import { css } from "styled-components";
import { Blocks } from "../components/blocks";
import { Footer } from "../components/footer";
import { SEO } from "../components/seo";
import Img, { FluidObject } from "gatsby-image";

type Props = {
  data: StoryPageQuery;
};

const Story: FC<Props> = props => {
  const { remInPx, waveClearance } = useContext(LayoutContext);
  const pageTitleRef = useRef<HTMLHeadingElement>(null);

  return (
    <>
      <SEO {...props.data.wp.story?.seo} />

      <WavySection
        backgroundColor={colors.mediumBlue}
        style={{
          color: colors.white,
          paddingTop: `calc(${waveClearance} + ${2 * ySpaceRem}rem)`,
          paddingBottom: `${ySpaceRem}rem`,
          transition: "padding 0.25s ease",
        }}
      >
        <Center>
          <div style={{ fontSize: "1.1rem" }}>
            <Link to="/" style={{ textDecoration: "underline" }}>
              Home
            </Link>{" "}
            >{" "}
            <Link to="/verhalen/" style={{ textDecoration: "underline" }}>
              Verhalen
            </Link>{" "}
            >{" "}
            <span
              dangerouslySetInnerHTML={{
                __html: props.data.wp.story?.title || "",
              }}
            />
          </div>
        </Center>
      </WavySection>

      <div
        style={{
          paddingTop: `${1.5 * ySpaceRem}rem`,
          paddingBottom: `calc(${1.5 * ySpaceRem}rem + ${waveClearance})`,
        }}
      >
        <Center>
          <div style={{ maxWidth: `${32 * xSpaceRem}rem` }}>
            <div
              style={{
                ...(pageTitleRef.current &&
                  (() => {
                    const paddingYRem =
                      ySpaceRem / 2 -
                      ((pageTitleRef.current.getBoundingClientRect().height /
                        remInPx) %
                        (ySpaceRem / 2));

                    return {
                      paddingTop: `${0.5 * paddingYRem}rem`,
                      paddingBottom: `${0.5 * paddingYRem}rem`,
                    };
                  })()),
                transition: "padding 0.25s ease",
                marginBottom: `${ySpaceRem}rem`,
              }}
            >
              <h1
                ref={pageTitleRef}
                style={{
                  fontFamily: fontStacks.cubano,
                }}
                css={(() => {
                  const minFontSizeRem = 2;
                  const maxFontSizeRem = 2.8888;
                  const vwRangeStartPx = 500;

                  return css`
                    font-size: ${(minFontSizeRem *
                      (remInPx || targetBaseFontSizePx)) /
                      (vwRangeStartPx / 100)}vw;
                    line-height: 1;

                    @media (max-width: ${vwRangeStartPx}px) {
                      font-size: ${minFontSizeRem}rem;
                    }

                    @media (min-width: ${vwRangeStartPx *
                        (maxFontSizeRem / minFontSizeRem)}px) {
                      font-size: ${maxFontSizeRem}rem;
                    }
                  `;
                })()}
                dangerouslySetInnerHTML={{
                  __html: props.data.wp.story?.title || "",
                }}
              />
            </div>

            <div style={{ marginBottom: `${0.5 * ySpaceRem}rem` }}>
              <Img
                fluid={
                  props.data.wp.story?.featuredImage?.node?.localFile
                    ?.childImageSharp?.fluid as FluidObject
                }
                style={{ maxWidth: "28rem" }}
              />
            </div>

            <Blocks blocks={props.data.wp.story?.blocks as BlockFragment[]} />
          </div>
        </Center>
      </div>

      <Footer backgroundColor={colors.lightestGrey} />
    </>
  );
};

export default Story;

export const pageQuery = graphql`
  query StoryPage($id: ID!) {
    wp {
      story(id: $id, idType: ID) {
        ...Story
        blocks {
          ...Block
        }
        title
        seo {
          ...SEO
        }
      }
    }
  }
`;
